import CustomIcon from '@components/common/CustomIcon/CustomIcon';
import { IconWrapper } from '@components/common/IconWrapper';
import TuEquipoIcon from '@components/common/navbar/Icons/TuEquipoIcon';
import { LicenseService } from '@services/LicenseService';
import dayjs from 'dayjs';
import { ViewType } from 'react-big-scheduler-stch';
import { LicensesRoutes, routeLayout } from 'src/paths';
import theme from 'src/theme';

export const automaticApprovalHours = '48';
export const timeToUploadCertificate = '24';
export const timeToRequestMedicalExtension = 24 * 7;

export const ReactQueryKeys = {
  CURRENT_USER: 'currentUser',
  STATIC_CONTENT: 'staticContent',
  INSTITUTIONALS: 'institutionals',
  LICENSES: 'licenses',
  LICENSES_BOSS: 'licensesBoss',
  EMERGENCIES: 'emergencies',
  DETAIL: 'detalle',
  TUTORIALS: 'tutorials',
  HOME: 'home',
  SHORCUTS: 'shorcuts',
  GANTT: 'gantt',
  TU_ARSAT: 'tuArsat',
  BIRTHDAY: 'birthday',
  NOTES: 'notas',
  OTHERS_LICENSE: 'othersLicense',
  TOPICS: 'topics',
  AUTOCOMPLETE_USER_BOSS: ['autocompleteUsersBoss'],
  AUTOCOMPLETE_USER_SHARE: ['autocompleteUsersShare'],
  CONTACTS_DIRECTORY: 'contacts_directory',
  INDEX_DIRECTORY: 'directoryIndex',
  MANAGMENT: 'managements',
  PAYCHECK: 'recibos',
  TAGS_PAYCHEKS: 'tagsPaycheck',
  UNVISUALIZED_PAYCHECKS: 'unvisualizedPaychecks',
  DEFAULT_PAYCHECK_PASSWORD: 'passwordPaycheckDefault',
  SEARCH: 'search',
  SUGGESTED_SEARCHES: 'suggestedSearches',
  CHECK_STATUS: 'checkStatus',
  TOPES: 'topes',
  SUPPLEMETARY_WORK_CONFLICTS: 'supplementaryWorkConflicts',
  SUPPLEMETARY_WORK_HISTORY: 'supplementaryWorkHistory',
  SUPPLEMETARY_WORK_HISTORY_TEAM: 'supplementaryWorkHistoryTeam',
  SUPPLEMETARY_WORK_PENDING: 'supplementaryWorkPending',
  SUPPLEMETARY_WORK_YOURTEAM: 'supplementaryWorkYourTeam',
  SUPPLEMETARY_WORK_AUTOCOMPLETE_CYCLE: 'supplementaryWorkAutocompleteCycle',
  SURVEY: 'survey',
  FORM_ANSWER: 'formularioAnswer',
  ANSWER: 'answer',
};

export const ErrorCodes = {
  TOKEN_EXPIRED: 'token-expired',
  TOKEN_INVALID: 'token-invalid',
  NETWORK_ERROR: 'network-error',
  LOGOUT: 'logout',
  COMMUNICATION_NOT_FOUND: 'contenido-inexistente',
  USER_SHARED_NOT_VALID: 'usuario-compartido-no-valido',
};

export const BORoles = {
  SUPER_ADMIN: 'super_admin',
  HUMAN_RESOURCES: 'human_resources',
  STAFF_MANAGER: 'staff_manager',
  PAYROLL: 'payroll',
  BENEFITS: 'benefits',
  COMMUNICATION: 'communication',
  MEDIC: 'medic',
  VACATION: 'vacation',
  PROCEDURE: 'procedure',
};

export const SpecialsRole = {
  SYSTEM: 'system',
};

export const CommonRoles = {
  EMPLOYEE: 'employee',
};

export const AuthMode = {
  LDAP: 'ldap',
  INTERNAL: 'internal',
};

export const iconSize = {
  sidebar: 24,
  tables: 24,
  main: 36,
};

export const BORowsPerPageOptions = [10, 20, 100, 500, 1_000, 2_000];
export const BORowsPerPageOptionsSmall = [5, 10, 20, 100, 500];

export const LICENSE_ERRORS = (days) => {
  return {
    'limit-of-days-reached': `La cantidad de días no puede ser mayor a ${days} dias`,
  };
};

export const LOGIN_ERRORS = {
  'login-user-not-exists':
    'El usuario no está vinculado correctamente, comuníquese con soporte.',
  'login-wrong-password': 'Contraseña incorrecta',
  'login-email-not-confirmed':
    'Para completar el registro es necesario que nos confirmes tu correo electrónico.',
  'login-expired-token': 'Expiró el token. Cerrá la sesión y volvé a ingresar.',
  'login-bad-token':
    'Error con el token. Si persiste cerrá la sesión y volvé a ingresar.',
  'login-wrong-user-or-password': 'El usuario o la contraseña son incorrectos.',
  'login-error': 'Error de ldap. Informalo a soporte (soporte@arsat.com.ar)',
  'login-user-not-in-db':
    'El usuario no está en Ceres. Informalo a RRHH para resolver la situación.',
};

export const COMMON_ERRORS = {
  ...LOGIN_ERRORS,
  'id-user-and-roles-required': 'El id y los roles son requeridos',
  'not-enough-permissions':
    'El usuario no está en el grupo de seguridad de administradores. Informalo a soporte (soporte@arsat.com.ar)',
  'connection-error':
    'Falló la conexión con ldap. Informalo a soporte (soporte@arsat.com.ar)',
  'user-token-failed':
    'Error en el armado del token. Informalo a soporte (soporte@arsat.com.ar)',
  dismissed:
    'El usuario fue dado de baja en Ceres. Informalo a RRHH para resolver la situación.',
  duplicated_id:
    'El id del usuario se encuentra duplicado en Ceres. Informalo a RRHH para resolver la situación.',
  duplicated_file_number:
    'El legajo del usuario se encuentra duplicado en Ceres. Informalo a RRHH para resolver la situación.',
  supervisor_loop:
    'El usuario figura como su propio superior en Ceres. Informalo a RRHH para resolver la situación.',
  supervisor_none:
    'El usuario no tiene superior en Ceres. Informalo a RRHH para resolver la situación.',
  supervisor_not_exists:
    'El superior del usuario no existe en Ceres. Informalo a RRHH para resolver la situación.',
  supervisor_dismissal:
    'El superior del usuario fue dado de baja en Ceres. Informalo a RRHH para resolver la situación.',
  'anio-no-corriente': 'Los días para el año próximo aún no están habilitados',
  'fecha-supera-maximo-pasado': 'La fecha no puede ser pasada.',
};

export const ERROR_TAGS_EMPLOYES = {
  'etiqueta-already-exists': 'La etiqueta ya existe',
  'etiqueta-no-disponible':
    'Las etiquetas "all" y "todos" son de uso reservado por el sistema',
};

export const COMMON_MESSAGES = {
  error: 'Ocurrió un error',
  success: 'Guardado con éxito',
};

export const WARNINGS = {
  'consecutive-medical-license':
    'La fecha seleccionada es contigua con otra licencia por enfermedad. Si se trata de la misma licencia podés extender la original. ',
  'coincident-medical-license':
    'La fecha seleccionada se superpone con otra licencia por enfermedad. Si se trata de la misma licencia podés extender la original sino modificá la fecha para generar una nueva.',
  'not-multiple-of-7':
    'La cantidad de días solicitados o el saldo de días restantes deberían ser múltiplos de 7.',
  'not-enough-days':
    'La cantidad de días solicitados supera los días disponibles.',
  'not-monday': 'El día de inicio de vacaciones no es lunes',
  'coincident-license':
    'Las fechas solicitadas coinciden con otra licencia aprobada o en proceso.',
  'consecutive-vacation-license':
    'No puede ser consecutivo al período de vacaciones.',
  'consecutive-procedure-license':
    'La fecha solicitada es consecutiva o inmediatamente anterior a un día de trámite.',
  'not-working-day': 'El día solicitado es feriado o fin de semana.',
  'from-date-not-working-day': 'El día de inicio es feriado o fin de semana',
  'not-ongoing-studies': 'No se registran estudios en curso.',
  'not-working-day-rotativo':
    'El día solicitado es feriado o fin de semana, pero registra turnos rotativos.',
  'not-monday-rotativo':
    'El día de inicio de vacaciones no es lunes, pero registra turnos rotativos.',
  'from-date-not-working-day-rotativo':
    'El día de inicio es feriado o fin de semana, pero registra turnos rotativos.',
};
export const WARNINGNAMES = {
  coincidentMedicalLicence: 'coincident-medical-license',
};

export const LICENSES_TYPES = {
  vacaciones: 'vacation',
  tramites: 'procedure',
  examen: 'exam',
  enfermedad: 'medical',
  otras: 'others',
};

export const ICONS = {
  vacation: 'Sunglasses',
  exam: 'GraduationCap',
  procedure: 'PersonSimpleRun',
  vacaciones: 'Sunglasses',
  tramite: 'PersonSimpleRun',
  examen: 'GraduationCap',
  enfermedad: 'Pill',
  enfermedad_familiar: 'UsersFour',
  ART: 'FirstAid',
  donacion_de_sangre: 'Drop',
  mudanza: 'Package',
  matrimonio: 'Heart',
  maternidad: 'Baby',
  paternidad: 'Baby',
  excedencia: 'Baby',
  sin_goce_de_sueldo: 'BagSimpleCross',
  citacion_judicial: 'Scales',
  fallecimiento_familiar: 'HandHeart',
  bomberos_voluntarios: 'FireExtinguisher',
};

export const NO_CONTENT_TEXT = {
  suplementaryWork:
    'Actualmente no tenés solicitudes de trabajo suplementario.',
  licences: 'Actualmente no tenés solicitudes de licencia activas.',
};

export const licenseURLtypes = {
  vacaciones: 'vacation',
  tramites: 'procedure',
  enfermedad: 'medical',
  examen: 'exam',
  medical: 'enfermedad',
  mudanza: 'Mudanza',
  matrimonio: 'Matrimonio',
  maternidad: 'Maternidad',
  paternidad: 'Paternidad',
  excedencia: 'Excedencia',
  sin_goce_de_sueldo: 'Sin Goce de Sueldo',
  citacion_judicial: 'Citación Judicial',
  fallecimiento_familiar: 'Fallecimiento familiar',
  deportiva: 'Deportiva',
  gremial: 'Gremial',
  religiosa: 'Religiosa',
  bomberos: 'Bomberos voluntarios',
};
export const LICENSES_TYPES_FULL_NAME = {
  vacation: 'Vacaciones',
  procedure: 'Día de Trámite',
  exam: 'Día de examen',
  medical: 'Enfermedad',
  mudanza: 'Mudanza',
  matrimonio: 'Matrimonio',
  maternidad: 'Maternidad',
  paternidad: 'Paternidad',
  excedencia: 'Excedencia',
  sin_goce_de_sueldo: 'Sin Goce de Sueldo',
  citacion_judicial: 'Citación Judicial',
  fallecimiento_familiar: 'Fallecimiento familiar',
  deportiva: 'Deportiva',
  gremial: 'Gremial',
  religiosa: 'Religiosa',
  bomberos: 'Bomberos voluntarios',
};

export const LICENSES_OPTIONS_SPECIALS = {
  all: 'Todos',
};

export const LICENSES_TYPES_FULL_NAME_FOR_ALL_REQUESTS = {
  ...LICENSES_TYPES_FULL_NAME,
  procedure: 'Trámite',
  exam: 'Examen',
  ...LICENSES_OPTIONS_SPECIALS,
};

export const TREE_STATUS_KEYS = {
  inProcess: 'in_process',
  hrPending: 'hr_pending',
  rejected: 'rejected',
  confirmed: 'confirmed',
  cancelled: 'cancelled',
  interrupted: 'interrupted',
  rrhhApproved: 'rrhh_approved',
  standBy: 'stand_by',
  documentPending: 'document_pending',
  documentLoaded: 'document_loaded',
  justified: 'justified',
  notJustified: 'not_justified',
  toBeDiscounted: 'to_be_discounted',
  modified: 'modified',
  notToBeDiscounted: 'not_to_be_discounted',
  actionRequired: 'accion_requerida',
  automaticApproval: 'aprobacion_24_hs',
  extensionInEvaluation: 'extension_en_evaluacion',
  objected: 'objected',
};

export const TREE_STATUS_AUTHORIZATION = {
  manual: 'manual',
  automaticApproval: 'automatica_24_hs',
};

export const WORKFLOW_STATUS = { FINISHED: 'Finished' };

const inProcces = { in_process: 'En Proceso' };
const hrPending = { hr_pending: 'En RRHH' };
const rejected = { rejected: 'Rechazada' };
const rrhhApproved = { rrhh_approved: 'Aprobada' };
const cancelled = { cancelled: 'Cancelada' };
const documentPending = { document_pending: 'Falta documentación' };
const documentLoaded = { document_loaded: 'Documentación en evaluación' };
const toBeDiscounted = { to_be_discounted: 'A descuento' };
const notToBeDiscounted = { not_to_be_discounted: 'Sin descuento' };
const interrupted = { interrupted: 'Interrumpida' };
const justified = { justified: 'Justificada' };
const notJustified = { not_justified: 'Sin justificar' };
const actionRequired = { accion_requerida: 'Accion requerida' };
const automaticApproval = {
  aprobacion_24_hs: `Aprobación automática ${automaticApprovalHours}hs`,
};
const extensionInEvaluation = {
  extension_en_evaluacion: 'Extensión en evaluación',
};
const objected = {
  objected: 'Extensión objetada',
};
const manual = {
  manual: 'Registro manual',
};

export const APPROVAL_EVENT = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const LICENSES_STATUS = {
  ...inProcces,
  ...hrPending,
  ...rejected,
  ...rrhhApproved,
  ...cancelled,
  ...documentPending,
  ...documentLoaded,
  ...toBeDiscounted,
  ...notToBeDiscounted,
  ...interrupted,
  ...justified,
  ...notJustified,
  ...objected,
  ...extensionInEvaluation,
  ...manual,
  ...LICENSES_OPTIONS_SPECIALS,
};

export const LICENSES_GANTT_STATUS = {
  ...inProcces,
  ...hrPending,
  ...rrhhApproved,
  ...documentPending,
  ...documentLoaded,
  ...toBeDiscounted,
  ...notToBeDiscounted,
  ...interrupted,
  ...justified,
  ...notJustified,
  ...objected,
  ...extensionInEvaluation,
  ...manual,
  ...LICENSES_OPTIONS_SPECIALS,
};

export const LICENSES_STATUS_KEYS = {
  in_process: 'in_process',
  hr_pending: 'hr_pending',
  rejected: 'rejected',
  rrhh_approved: 'rrhh_approved',
  cancelled: 'cancelled',
  document_pending: 'document_pending',
  document_loaded: 'document_loaded',
  to_be_discounted: 'to_be_discounted',
  not_to_be_discounted: 'not_to_be_discounted',
  interrupted: 'interrupted',
  justified: 'justified',
  not_justified: 'not_justified',
  objected: 'objected',
  extension_en_evaluacion: 'extension_en_evaluacion',
  manual: 'manual',
  all: 'all',
};

export const APROVAL_STATUS_DESCRIPTIONS = {
  document_pending: {
    medical: `El solicitante tiene ${automaticApprovalHours} horas desde el inicio de la ausencia para cargar el justificante, de lo contrario se descontarán los días no trabajados.`,
    exam: 'El solicitante tiene 30 días desde el inicio de la ausencia para cargar el justificante, de lo contrario se le descontarán los días no trabajados.',
  },
  document_loaded:
    'El solicitante adjuntó el certificado correspondiente y se encuentra a la espera de la resolución de Recursos Humanos.',
  objected: {
    medical:
      'El Servicio Médico objetó la solicitud de extensión de la licencia.',
  },
  extension_en_evaluacion: {
    medical:
      'El solicitante pidió una extensión de la licencia adjuntando el certificado correspondiente y se encuentra a la espera de la resolución del Servicio Médico.',
  },
};
export const STATUS_DESCRIPTIONS = {
  document_pending: {
    medical: `Tenés ${timeToUploadCertificate} horas desde el inicio de la ausencia para cargar el justificante, de lo contrario se descontarán los días no trabajados.`,
    exam: 'Tenés 30 días desde el inicio de la ausencia para cargar el justificante, de lo contrario se descontarán los días no trabajados.',
  },
  document_loaded: {
    medical:
      'En cuanto el Servicio Médico verifique la información adjunta, recibirás una notificación con los resultados.',
    exam: 'En cuanto Recursos Humanos verifique la información adjunta, recibirás una notificación con los resultados.',
  },
  extension_en_evaluacion: {
    medical:
      'En cuanto el Servicio Médico verifique la información adjunta, recibirás una notificación con los resultados.',
  },
};

export const TREE_STATUS_REFUND_DAYS = {
  interrupted: 'devueltos',
  to_be_discounted: 'a descontar',
  default: 'solicitados',
};

export const LICENSES_STATUS_BOSS = {
  ...LICENSES_STATUS,
  ...actionRequired,
  ...automaticApproval,
};
export const STATES_PAYCHECKS = {
  visto: 'Visto',
  no_visto: 'No visto',
  all: 'Todos',
};
export const STATES_PAYCHECKS_KEYS = {
  visto: 'visto',
  no_visto: 'no_visto',
};

export const STATE_HISTORY_EMERGENCY = {
  // programada: 'Programada', // no está en el back
  registrada: 'Registrada',
  aprobada: 'Aprobada',
  rechazada: 'Rechazada',
  en_rrhh: 'Informada a RRHH',
  anulada: 'Anulada',
  // declinada: 'Declinada', //no esta en el back, es rechazada?
  // vencida: 'Vencida', //no esta en el back
  cancelada: 'Cancelada', //estan en back pero no en figma
  all: 'Todos',
};

export const TYPE_HISTORY = {
  emergencia: 'Activación de emergencia',
  guardia: 'Activación de guardia',
  horas_extras_programadas: 'Horas extra programadas',
  all: 'Todos',
};

export const PARTICULAR_CASES = {
  true: 'Si',
  false: 'No',
  all: 'Sin filtrar',
};

export const TREE_STATUS = {
  authorized: 'Autorizó',
  loaded: 'Solicitud generada',
  rejected: 'Rechazó',
  confirmed: 'Aprobó',
  'not-objected': 'No objetó',
  cancelled: 'Canceló',
  interrupted: 'Interrumpió',
  rrhh_approved: 'Aprobó recursos humanos',
  stand_by: 'En revisión',
  pending: 'Pendiente',
  document_loaded: 'Documentación en evaluación',
  'file-uploaded': 'Documentación enviada',
  justified: 'Justificó',
  not_justified: 'Sin justificar',
  to_be_discounted: 'A descontar',
  modified: 'Licencia modificada',
  not_to_be_discounted: 'Sin descuento',
  objected: 'Objetó',
  extension_en_evaluacion: 'Extensión solicitada',
  mensaje_user: 'Mensaje enviado',
  mensaje_doctor: 'Mensaje recibido',
};

export const TREE_STATUS_WHITH_NAME = [
  'authorized',
  'confirmed',
  'cancelled',
  'not-objected',
];
export const TREE_STATUS_WHITH_NAME_MEDICAL = [
  ...TREE_STATUS_WHITH_NAME,
  'file-uploaded',
  'modified',
  'justified',
  'objected',
];

export const CARD_COLOR = {
  approved: '#2E7D32',
  rrhh_approved: '#2E7D32',
  justified: '#2E7D32',
  in_process: '#50B8B1',
  hr_pending: '#50B8B1',
  rejected: '#D32F2F',
  to_be_discounted: '#D32F2F',
  not_justified: '#D32F2F',
  document_pending: '#ED6C02',
  document_loaded: '#ED6C02',
  interrupted: '#535554',
  cancelled: '#535554',
  objected: '#535554',
  extension_en_evaluacion: '#ED6C02',
  registrada: '#50B8B1',
  aprobada: '#2E7D32',
  en_rrhh: '#2E7D32',
  rechazada: '#D32F2F',
  anulada: '#D32F2F',
  cancelada: '#535554',
};

export const CARD_DETAIL_COLOR = {
  approved: '#D2E9D4',
  in_process: '#C7DCDA',
  hr_pending: '#C7DCDA',
  rejected: '#FFD2D2',
  interrupted: '#D4D5D5',
  cancelled: '#D4D5D5',
  document_pending: '#FFEEE1',
  document_loaded: '#FFEEE1',
  to_be_discounted: '#FFD2D2',
  not_justified: '#FFD2D2',
  rrhh_approved: '#D2E9D4',
  justified: '#D2E9D4',
  extension_en_evaluacion: '#FFEEE1',
  objected: '#D4D5D5',
  registrada: '#C7DCDA',
  aprobada: '#D2E9D4',
  en_rrhh: '#D2E9D4',
  rechazada: '#FFD2D2',
  anulada: '#FFD2D2',
  cancelada: '#D4D5D5',
};

export const TREE_STATUS_NOTES = {
  authorized: 'Solicitud autorizada',
  'not-objected': 'Solicitud no objetada',
  loaded: 'Solicitud generada',
  rejected: 'Solicitud rechazada',
  confirmed: 'Solicitud aprobada',
  cancelled: 'Solicitud cancelada',
  interrupted: 'Solicitud interrumpida',
  rrhh_approved: 'Solicitud aprobada por recursos humanos',
  stand_by: 'Solicitud en espera',
  pending: 'Solicitud pendiente',
  document_loaded: 'Documentación enviada',
  justified: 'Solicitud justificada',
  not_justified: 'Solicitud sin justificar',
  to_be_discounted: 'Solicitud a descontar',
  modified: 'Solicitud modificada',
  not_to_be_discounted: 'Solicitud sin descuento',
  objected: 'Solicitud objetada',
  mensaje_user: 'Mensaje enviado',
  mensaje_doctor: 'Mensaje recibido',
  objetada: 'Registro objetado',
};

export const IconProps = (color) => {
  return {
    fontSize: 'large',
    sx: {
      padding: '5px',
      borderRadius: '50%',
      backgroundColor: color,
      color: 'white',
      width: '28px',
      height: '28px',
    },
  };
};

const IconLicensesCard = ({ name, color = '#ffffff', bg }) => (
  <IconWrapper background={bg} height={30} width={30} padding={'4px'}>
    <CustomIcon name={name} color={color} />
  </IconWrapper>
);

export const LICENSES_STATUS_ICONS = {
  rejected: <IconLicensesCard name="XCircle" bg={'#d32f2f'} />,
  to_be_discounted: <IconLicensesCard name="WarningCircle" bg={'#d32f2f'} />,
  not_justified: <IconLicensesCard name="WarningCircle" bg={'#d32f2f'} />,
  approved: <IconLicensesCard name="CheckCircle" bg={'#2e7d32'} />,
  rrhh_approved: <IconLicensesCard name="CheckCircle" bg={'#2e7d32'} />,
  justified: <IconLicensesCard name="CheckCircle" bg={'#2e7d32'} />,
  interrupted: <IconLicensesCard name="Prohibit" bg={'#535554'} />,
  document_pending: <IconLicensesCard name="WarningCircle" bg={'#ed6c02'} />,
  document_loaded: <IconLicensesCard name="Clock" bg={'#ed6c02'} />,
  in_process: <IconLicensesCard name="Clock" bg={'#50B8B1'} />,
  hr_pending: <IconLicensesCard name="Clock" bg={'#50B8B1'} />,
  cancelled: <IconLicensesCard name="Prohibit" bg={'#535554'} />,
  objected: <IconLicensesCard name="Prohibit" bg={'#535554'} />,
  extension_en_evaluacion: <IconLicensesCard name="Clock" bg={'#ed6c02'} />,
};

export const EMERGENCY_STATUS_ICONS = {
  registrada: <IconLicensesCard name="Clock" bg={'#50B8B1'} />,
  aprobada: <IconLicensesCard name="CheckCircle" bg={'#2e7d32'} />,
  en_rrhh: <IconLicensesCard name="CheckCircle" bg={'#2e7d32'} />,
  rechazada: <IconLicensesCard name="XCircle" bg={'#d32f2f'} />,
  anulada: <IconLicensesCard name="XCircle" bg={'#d32f2f'} />,
  cancelada: <IconLicensesCard name="Prohibit" bg={'#535554'} />,
};

export const MODALITIES = {
  full_day: 'Día completo',
  afternoon: 'Medio día (tarde)',
  morning: 'Medio día (mañana)',
};

export const MANUAL_LICENSE = 'manual';

export const MODALITY_OPTIONS = [
  {
    label: 'Día completo',
    value: 'full_day',
  },
  {
    label: 'Medio día (tarde)',
    value: 'afternoon',
  },
  {
    label: 'Medio día (mañana)',
    value: 'morning',
  },
];

export const MODALITY_TEXT_LICENSE_IN_PROGRESS = {
  full_day: 'completo',
  afternoon: 'tarde',
  morning: 'mañana',
};
export const DATE_FORMAT = {
  STANDARD: 'DD/MM/YYYY',
  STANDARD_LIGHT: 'DD/MM/YY',
  STANDARD_WITHOUT_YEAR: 'DD/MM',
  STANDARD_TIME: 'DD/MM/YYYY - HH:mm',
  STANDARD_TIME_WITHOUT_YEAR: 'DD/MM - HH:mm',
  STANDARD_TIME_WITH_DAY: 'dddd DD/MM/YYYY - HH:mm',
  READABLE_TIME: 'DD/MM/YYYY HH:mm',
  READABLE_DAY: 'DD/MM dddd',
  READABLE_DAY_WITH_READABLE_MONTH: 'dddd DD MMMM',
  READABLE_DAY_FIRST: 'dddd DD/MM',
  READABLE_MONTH: 'DD MMMM YYYY',
  READABLE_MONTH_WITH_YEAR: 'MMMM YYYY',
  TIME: 'HH:mm',
  TIME_WITH_DOTS: 'H.mm',
  MONTH: 'mm',
  YEAR: 'YYYY',
  HISTORY: 'D MMM YYYY',
  SCHEDULER: 'YYYY-MM-DD HH:mm:ss',
};

export const LICENSES_TYPES_NAME_TABLE = {
  vacation: 'Vacaciones',
  procedure: 'Trámite',
  exam: 'Examen',
  medical: 'Enfermedad',
  others: 'Otras licencias (Descargables/Manual)',
  mudanza: 'Mudanza',
  matrimonio: 'Matrimonio',
  maternidad: 'Maternidad',
  paternidad: 'Paternidad',
  excedencia: 'Excedencia',
  sin_goce_de_sueldo: 'Sin Goce de Sueldo',
  citacion_judicial: 'Citación Judicial',
  fallecimiento_familiar: 'Fallecimiento familiar',
  deportiva: 'Deportiva',
  gremial: 'Gremial',
  religiosa: 'Religiosa',
  bomberos: 'Bomberos voluntarios',
};
export const SUPPLEMENTARY_WORK_TYPES_NAME_TABLE = {
  emergencia: 'Emergencia',
};

export const datePickerShortcuts = {
  lastMonth: 'Mes pasado',
  lastWeek: 'Última Semana',
  january: 'Enero',
};
export const urlParameter = {
  TYPE: 'type',
  STATUS: 'status',
};

export const defaultFiltersLicenses = {
  types: [],
  sort_field: 'from_date',
  sort_order: -1,
  page_num: 1,
  q: '',
  status_process: [],
  page_size: BORowsPerPageOptions[0],
  vista: 'tu_equipo',
  idUserCompartido: null,
};

export const defaultFilterTopes = {
  sort_field: '',
  sort_order: -1,
  page_num: 1,
  page_size: BORowsPerPageOptions[0],
  q: '',
};

export const defaultFilterSupplemnetaryWorks = {
  tipos: [],
  estados: [],
  from_date: null,
  to_date: null,
  sort_field: '',
  sort_order: -1,
  page_num: 1,
  page_size: BORowsPerPageOptions[0],
  caso_particular: true,
};
export const initValuesActionPendingBoss = {
  page_num: 1,
  page_size: BORowsPerPageOptionsSmall[0],
  vista: 'acciones_pendientes',
};
export const initValuesInProgressBoss = {
  page_num: 1,
  page_size: BORowsPerPageOptions[0],
  vista: 'en_curso',
};

export const navegationsRoutes = {
  allLicenses: '/todas',
};
export const pageSizeNotes = 12;
export const defaultFiltersNotes = {
  q: '',
  type: 'nota',
  page_num: 1,
  page_size: pageSizeNotes,
  sort_field: 'fecha',
};
export const defaultFiltersForInfiniteScroll = {
  pageNumber: 1,
  pageSize: 12,
};
export const defaultFiltersDirectory = {
  pageNumber: 1,
  pageSize: 12,
};

export const defaultFiltersPaycheck = {
  pageNumber: 1,
  pageSize: 12,
  type: [],
};

export const searchSuggestedMock = [
  'Contactos',
  'Instructivo',
  'Recursos',
  'Políticas',
];

export const statusMeaning = {
  100: 'Continue',
  101: 'Switching protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found (Previously "Moved Temporarily")',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I"m a Teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export const STATUS_PROCESS_AUTHORIZATION = {
  manual: 'Manual',
  automatica_24_hs: `Automática ${automaticApprovalHours}h`,
};

export const COMMUNICATION_TYPES = {
  note: 'nota',
  page: 'pagina',
  communication: 'comunicado',
};

export const allItemsFilter = {
  label: 'Todos',
  value: 'all',
};

export const USEFUL_CONTACTS = 'contactos_utiles';
export const EMAILS = {
  tecnical: 'soporte@arsat.com.ar',
  cybersecurity: 'ciberseguridad@arsat.com.ar',
  suggestions: 'portaldeempleados@arsat.com.ar',
};

export const AVATAR_TYPE = {
  default: 'default',
  phone: 'phone',
  notes: 'NOTAS',
  yourArsat: 'TU ARSAT',
  yourTeam: 'TU EQUIPO',
  contactDirectory: 'DIRECTORIO DE CONTACTOS',
  institutional: 'INSTITUCIONAL',
  access: 'ACCESO DIRECTO',
};

export const TOOLS = {
  webmail: {
    title: 'Webmail',
    description: 'Tu correo electrónico institucional',
    icon: <CustomIcon name="Envelope" size={22} />,
  },
  conference: {
    title: 'Conference',
    description: 'Plataforma de videoconferencias',
    icon: <CustomIcon name="Microphone" size={22} />,
  },
  comedor: {
    title: 'Comedor',
    description: 'Sistema de reservas del menú diario',
    icon: <CustomIcon name="ForkKnife" size={22} />,
  },
  'bo-portal': {
    title: 'Backoffice Portal de Empleados',
    description: 'Administración del portal',
    icon: <CustomIcon name="FolderUser" size={22} />,
  },
  erp: {
    title: 'ERP Ceres',
    description: 'Sistema de gestión integral',
    icon: <CustomIcon name="Gear" size={22} />,
  },
  maximo: {
    title: 'MAXIMO',
    description: 'Sistema de Gestión EAM y OSS',
    icon: <CustomIcon name="Gear" size={22} />,
  },
  arca2: {
    title: 'Arca 2.0 - Nuevo',
    description: 'Almacenamiento en la nube',
    icon: <CustomIcon name="CloudArrowUp" size={22} />,
  },
  spec: {
    title: 'SPEC',
    description: 'Control de presentismo',
    icon: <CustomIcon name="IdentificationBadge" size={22} />,
  },
  usi: {
    title: 'USI',
    description: 'Sistema de apoyo a la gestión (GTI)',
    icon: <CustomIcon name="Gear" size={22} />,
  },
  capacitaciones: {
    title: 'Capacitaciones',
    description: 'Portal de formación permanente',
    icon: <CustomIcon name="MonitorPlay" size={22} />,
  },
};

export const INSTITUTIONALS = {
  beneficios: {
    title: 'Beneficios',
    description: 'Conocé el programa Arsat para vos',
    icon: <CustomIcon name="Gift" />,
  },
  traslados: {
    title: 'Traslados',
    description: 'Servicios y recorridos de las combis',
    icon: <CustomIcon name="Bus" />,
  },
  comedor: {
    title: 'Comedor',
    description: 'Acerca del servicio de comedor',
    icon: <CustomIcon name="ForkKnife" />,
  },
  capacitaciones: {
    title: 'Capacitaciones',
    description: 'Portal de formación',
    icon: <CustomIcon name="MonitorPlay" />,
  },
  contactos: {
    title: 'Contactos Útiles',
    description: 'Listado de contactos de interés',
    icon: <CustomIcon name="Phone" />,
  },
  'plantillas-recursos': {
    title: 'Plantillas y Recursos',
    description: 'Descargables para tus presentaciones',
    icon: <CustomIcon name="Palette" />,
  },
  'politicas-procedimientos': {
    title: 'Políticas y Procedimientos',
    description: 'Conocé las normas internas',
    icon: <CustomIcon name="ListChecks" />,
  },
  formularios: {
    title: 'Formularios',
    description: 'Descargables para gestiones varias',
    icon: <CustomIcon name="Files" />,
  },
  busquedas: {
    title: 'Búsquedas Internas',
    description: 'Conocé las búsquedas laborales vigentes',
    icon: <CustomIcon name="UserCirclePlus" />,
  },
  bancos: {
    title: 'Bancos',
    description: 'Los oficiales de cuenta de cada banco',
    icon: <CustomIcon name="Bank" />,
  },
  art: {
    title: 'ART',
    description: 'Aseguradoras de Riesgos del Trabajo',
    icon: <CustomIcon name="FirstAid" />,
  },
  contactDirectory: {
    //TODO cambiar a directorio-contactos cuando cambien el get de institucional
    title: 'Directorio de Contactos',
    description: 'Accedé a la información de los empleados',
    icon: <CustomIcon name="AddressBook" />,
  },
  'directorio-contactos': {
    title: 'Directorio de Contactos',
    description: 'Accedé a la información de los empleados',
    icon: <CustomIcon name="AddressBook" />,
  },
  webmail: {
    title: 'Webmail',
    description: 'Tu correo electrónico institucional.',
    icon: <CustomIcon name="Envelope" />,
  },
  conference: {
    title: 'Conference',
    description: 'Plataforma de videoconferencias.',
    icon: <CustomIcon name="Microphone" />,
  },
  arca: {
    title: 'Arca',
    description: 'Almacenamiento en la nube.',
    icon: <CustomIcon name="CloudArrowUp" />,
  },
  arca2: {
    title: 'Arca 2.0',
    description: 'Almacenamiento en la nube.',
    icon: <CustomIcon name="CloudArrowUp" />,
  },
  erpCeres: {
    //TODO SACAR CUANDO Cambien el get de institucional
    title: 'ERP Ceres',
    description: 'Sistema de gestión integral.',
    icon: <CustomIcon name="Gear" />,
  },
  ceres: {
    title: 'ERP Ceres',
    description: 'Sistema de gestión integral.',
    icon: <CustomIcon name="Gear" />,
  },
  'portal-bo': {
    title: 'Backoffice Portal de Empleados',
    description: 'Administración del portal.',
    icon: <CustomIcon name="FolderUser" />,
  },
  maximo: {
    title: 'Maximo',
    description: 'Sistema de Gestión EAM y OSS.',
    icon: <CustomIcon name="Gear" />,
  },
  spec: {
    title: 'SPEC',
    description: 'Control de presentismo.',
    icon: <CustomIcon name="Gear" />,
  },
  usi: {
    title: 'USI',
    description: 'Sistema de apoyo a la gestión (GTI).',
    icon: <CustomIcon name="Gear" />,
  },
  'soporte-tecnico': {
    title: 'Soporte Técnico',
    description:
      'Si tenés inconvenientes técnicos llamá al 0348-447-8000 (Interno 2000) o escribí a soporte@arsat.com.ar',
    icon: <CustomIcon name="Headset" />,
  },
  'buzon-sugerencias': {
    title: 'Buzón de sugerencias',
    description:
      '¿Ves algo para mejorar? Comentanos por mail a portaldemepleados@arsat.com.ar',
    icon: <CustomIcon name="Lightbulb" />,
  },
  ciberseguridad: {
    title: 'Ciberseguridad',
    description:
      '¿Ves algo para mejorar? Comentanos por mail a portaldeempleados@arsat.com.ar',
    icon: <CustomIcon name="ShieldCheck" />,
  },
};

export const TU_ARSAT_ICONS = {
  'tu-arsat': {
    title: 'Tu ARSAT',
    icon: <CustomIcon name="UserCircle" />,
  },
  'tus-licencias': {
    icon: <CustomIcon name="UserCircle" />,
  },
  'tus-recibos': {
    icon: <CustomIcon name="UserCircle" />,
  },
  'historial-licencias': {
    icon: <CustomIcon name="UserCircle" />,
  },
  vacaciones: {
    icon: <CustomIcon name="Sunglasses" />,
  },
  tramite: {
    icon: <CustomIcon name="PersonSimpleRun" />,
  },
  examen: {
    icon: <CustomIcon name="GraduationCap" />,
  },
  enfermedad: {
    icon: <CustomIcon name="Pill" />,
  },
};

export const TU_EQUIPO_ICONS = {
  'tu-equipo': {
    title: 'Tu Equipo',
    icon: <TuEquipoIcon color={theme.palette.common.blue} />,
  },
  'listado-ausencias-programadas': {
    icon: <TuEquipoIcon color={theme.palette.common.blue} />,
  },
  'cronograma-ausencias-programadas': {
    icon: <TuEquipoIcon color={theme.palette.common.blue} />,
  },
};

export const SEARCH_ICONS = {
  ...TOOLS,
  ...INSTITUTIONALS,
  ...TU_ARSAT_ICONS,
  ...TU_EQUIPO_ICONS,
};

export const MOTIVO_DESHABILITADO = {
  'REQUIERE-VPN': 'Requiere conexión a la VPN',
};

export const interval = {
  homeSlider: '5000',
};

export const homeShortcutsDesciption = {
  Licencias: 'Realizá tus pedidos de licencias',
  Recibos: 'Descargá tus recibos de sueldo',
  Traslados: 'Conocé los recorridos',
  Beneficios: 'Programa Arsat para vos',
  TrabajoSuplementario: 'Gestioná tus horas extra',
};

export const Z_INDEX = {
  MINIMUM: 0,
  LOWER: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const itemBreadcrumbs = {
  tuArsat: { title: 'Tu ARSAT', link: '/tu-arsat' },
  tuEquipo: { title: 'Tu Equipo', link: '/equipo' },
  paychecks: { title: 'Recibos', link: '/recibos' },
  licencias: { title: 'Licencias', link: '/tu-arsat/licencias' },
  supplementaryWork: {
    title: 'Trabajo Suplementario',
    link: routeLayout.supplementaryWork,
  },

  supplementaryWorkTeamList: {
    title: 'Emergencias',
    link: routeLayout.supplementaryWorkTeamList,
  },
  supplementaryWorkTeam: {
    title: 'Trabajo Suplementario',
    link: routeLayout.supplementaryWorkTeam,
  },
  newEmergencyActivation: {
    title: 'Nueva Activación de Emergencia',
  },
  history: {
    title: 'Trabajos realizados',
    link: routeLayout.supplementaryWorkHistory,
  },
  topes: { title: 'Topes', link: '/topes' },
  vacation: { title: 'Vacaciones' },
  procedure: { title: 'Día de Trámite' },
  exam: { title: 'Día de Examen' },
  medical: { title: 'Enfermedad' },
  all: { title: 'Todas' },
  detail: { title: 'Detalle' },
  myTeam: { title: 'Tu equipo', link: '/equipo' },
  emergencies: {
    title: 'Emergencias',
    link: routeLayout.teamSupplementaryWorkEmergencyActivation,
  },
  activationDetail: { title: 'Detalle de la Activación' },
  allLicences: {
    title: 'Listado Todas las licencias',
    link: LicensesRoutes.listLicenses,
  },
  scheduleLicenses: {
    title: 'Cronograma de Ausencias',
    link: LicensesRoutes.schedulerLicenses,
  },
  institutional: { title: 'Institucional', link: '/institucional' },
  contactDirectory: { title: 'Directorio', link: '/directorio' },
  newActivationMyTeam: {
    title: 'Nueva Activación de Emergencia',
    link: routeLayout.addSupplementaryWork(),
  },
};
export const initActiveLicenses = {
  page_num: 1,
  page_size: 2,
  active: true,
};
export const initActiveLicensesTuArsat = {
  page_num: 1,
  page_size: 2,
};

export const breadcrumbsEmergencyDetails = [
  itemBreadcrumbs.myTeam,
  itemBreadcrumbs.supplementaryWorkTeam,
  itemBreadcrumbs.emergencies,
  itemBreadcrumbs.activationDetail,
];

export const breadcrumbsEmergencyDetailsTuArsatView = [
  itemBreadcrumbs.tuArsat,
  itemBreadcrumbs.supplementaryWork,
  itemBreadcrumbs.history,
  itemBreadcrumbs.activationDetail,
];

export const breadcrumbsLicences = [
  itemBreadcrumbs?.tuArsat,
  itemBreadcrumbs?.licencias,
];

export const breadcrumbsVacations = [
  ...breadcrumbsLicences,
  itemBreadcrumbs?.vacation,
];
export const breadcrumbsProcedure = [
  ...breadcrumbsLicences,
  itemBreadcrumbs?.procedure,
];

export const breadcrumbsExam = [...breadcrumbsLicences, itemBreadcrumbs?.exam];

export const breadcrumbsMedical = [
  ...breadcrumbsLicences,
  itemBreadcrumbs?.medical,
];
export const breadcrumbsAll = [...breadcrumbsLicences, itemBreadcrumbs?.all];
export const breadcrumbsAllLicenses = [
  itemBreadcrumbs?.myTeam,
  itemBreadcrumbs?.allLicences,
];

export const breadcrumbsScheduleLicenses = [
  itemBreadcrumbs?.myTeam,
  itemBreadcrumbs?.scheduleLicenses,
];

export const breadcrumbsRequestLicence = [
  ...breadcrumbsLicences,
  itemBreadcrumbs?.detail,
];
export const breadcrumbsIsAprovalView = [
  ...breadcrumbsAllLicenses,
  itemBreadcrumbs?.detail,
];

export const breadcrumbsContactDirectory = [
  itemBreadcrumbs?.institutional,
  itemBreadcrumbs?.contactDirectory,
];

export const breadcrumbsPaychecks = [
  itemBreadcrumbs?.tuArsat,
  itemBreadcrumbs?.paychecks,
];

export const breadcrumbsSupplementaryWork = [
  itemBreadcrumbs?.tuArsat,
  itemBreadcrumbs?.supplementaryWork,
];

export const breadcrumbsNewEmergencyActivation = [
  itemBreadcrumbs?.tuArsat,
  itemBreadcrumbs?.supplementaryWork,
  itemBreadcrumbs?.newEmergencyActivation,
];

export const breadcrumbsHistory = [
  itemBreadcrumbs?.tuArsat,
  itemBreadcrumbs?.supplementaryWork,
  itemBreadcrumbs?.history,
];

export const breadcrumbsSupplementaryWorkTeam = [
  itemBreadcrumbs?.tuEquipo,
  itemBreadcrumbs?.supplementaryWorkTeam,
];
export const breadcrumbsSupplementaryWorkTeamList = [
  ...breadcrumbsSupplementaryWorkTeam,
  itemBreadcrumbs?.supplementaryWorkTeamList,
];

export const breadcrumbsTopes = [
  itemBreadcrumbs?.tuEquipo,
  itemBreadcrumbs?.supplementaryWork,
  itemBreadcrumbs?.topes,
];

export const breadcrumbsNewSupplementaryWorkTeam = [
  ...breadcrumbsSupplementaryWorkTeamList,
  itemBreadcrumbs?.newActivationMyTeam,
];

export const MAX_LENGTH_FORMS = {
  medicalNew: {
    comentarios_para_medico: 500,
    notes: 500,
  },
  newEmergencyActivation: {
    comentrios: 500,
    numTicket: 6,
    description: 30,
  },
};

export const minDatePickerMonthYear = '2023-03-01';

export const LICENSES_ITEM_INSTRUCTIVE = [
  'enfermedad_familiar',
  'ART',
  'donacion_de_sangre',
  'mudanza',
  'matrimonio',
  'maternidad',
  'paternidad',
  'excedencia',
  'sin_goce_de_sueldo',
  'citacion_judicial',
  'fallecimiento_familiar',
  'bomberos_voluntarios',
];

export const SUPPLEMENTARY_WORK_LIST = [
  // { key: 'guard', link: '/', titulo: 'Activación de Guardia' },
  {
    key: 'emergency',
    link: '/tu-arsat/trabajo-suplementario/nueva-activacion-emergencia',
    titulo: 'Activación de Emergencia',
  },
  // { key: 'extraHours', link: '/', titulo: 'Horas Extra Programadas' },
];
export const SUPPLEMENTARY_WORK_STATE_COLOR = {
  registrada: '#50B8B1',
  aprobada: '#2E7D32',
  rechazada: '#D32F2F',
  en_rrhh: '#2E7D32',
  anulada: '#000000',
  cancelada: '#535554',
};

export const SUPPLEMENTARY_WORK_TYPE = {
  emergencia: 'Activación de emergencia',
  guardia: 'Activación de guardia',
  programadas: 'Horas extras programadas',
};

export const licenseTableStatusStepGrey = {
  ...documentPending,
  ...documentLoaded,
  ...extensionInEvaluation,
  hr_pending: 'Debe aprobar: RRHH -',
};
export const DROPZONE_MODAL_TYPE = {
  documents: 'documents',
  certificate: 'certificate',
  medicalExtension: 'medicalExtension',
  sendMessage: 'sendMessage',
};
export const DROPZONE_MODAL_UPLOADS = {
  documents: LicenseService.uploadDocuments,
  certificate: LicenseService.uploadCertificate,
  medicalExtension: LicenseService.uploadMedicalExtension,
  sendMessage: LicenseService.uploadAndSendMessage,
};

export const MANUAL_LICENSE_TYPES_NAMES = {
  enfermedad_familiar: 'Enfermedad Familiar',
  art: 'ART',
  donacion_de_sangre: 'Donación de Sangre',
  mudanza: 'Mudanza',
  matrimonio: 'Matrimonio',
  maternidad: 'Maternidad',
  paternidad: 'Paternidad',
  excedencia: 'Excedencia',
  sin_goce_de_sueldo: 'Sin Goce de Sueldo',
  franco_compensatorio: 'Franco Compensatorio',
  compensacion_de_horas: 'Compensación de Horas',
  bomberos: 'Bomberos',
  deportiva: 'Deportiva',
  gremial: 'Gremial',
  otros: 'Otros',
};
export const MANUAL_LICENSE_TYPE_OTHERS = 'otros';

export const LICENSES_ALL_TYPES = {
  ...LICENSES_TYPES_FULL_NAME,
  ...MANUAL_LICENSE_TYPES_NAMES,
  ...LICENSES_OPTIONS_SPECIALS,
};

export const ganttLicenses = {
  vacation: {
    color: { rrhh_approved: '#F89DC7' },
    title: 'Licencia por Vacaciones',
    shortTitle: 'Vacaciones',
  },
  medical: {
    color: { justified: '#A9DDD9' },
    title: 'Licencia por Enfermedad',
    shortTitle: 'Enfermedad',
  },
  procedure: {
    color: { rrhh_approved: '#FBCF9C' },
    title: 'Licencia por Trámite',
    shortTitle: 'Trámite',
  },
  exam: {
    color: {
      rrhh_approved: '#FBCF9C',
      justified: '#FBCF9C',
      document_pending: '#FBCF9C',
    },
    title: 'Licencia por Día de Examen',
    shortTitle: 'Examen',
  },
  manual: {
    color: '#BDB2DD',
    title: 'Licencia manual',
    shortTitle: 'Manual',
  },
};

export const schedulerInitialValues = {
  nonAgendaOtherCellHeaderFormat: 'DD',
  tableHeaderHeight: '40px',
  schedulerMaxHeight: '50px',
  weekCellWidth: '11%',
  monthCellWidth: '2.5%',
  views: [
    {
      viewName: 'Semana',
      viewType: ViewType.Week,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: 'Mes',
      viewType: ViewType.Month,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: 'Año',
      viewType: ViewType.Year,
      showAgenda: false,
      isEventPerspective: false,
    },
  ],
  resourceName: '',
  schedulerContentHeight: '100%',
  weekEndColorCell: theme.palette.grey[1],
  isTodayColorCell: '#BBDEFB',
  dragAndDropEnabled: false,
  checkConflict: true,
  besidesWidth: 0,
  monthResourceTableWidth: '20%',
  weekResourceTableWidth: '20%',
  schedulerWidth: '100%',
  nonAgendaSlotMinHeight: 45,
  yearCellWidth: 20,
  yearResourceTableWidth: '20%',
  defaultExpanded: false,
  headerEnabled: false,
  widthBalanceButton: 100,
};

export const staticDatePickerData = {
  views: { 2: ['year', 'month'], 1: ['year', 'month', 'day'], 4: ['year'] },
  minDate: dayjs('2023-07'),
  maxDate: dayjs('2040-01'),
};

export const slotItemContainerDiv = 'SlotItemContainer-';
export const tdSchedulerUserHeaders = 'td-scheduler-user-headers';
export const keyAutocompleteTagList = 'etiquetas_list';

export const sharedMenuOption = {
  forMy: 'forMy',
  withMe: 'withMe',
};
export const sharedPermissions = {
  reader: 'lector',
  editor: 'editor',
  deletePermissions: 'delete',
};

export const SHARED_PERMISOS_TYPES = [
  sharedPermissions.reader,
  sharedPermissions.editor,
];

export const PHONE_LABELS = {
  celular_corporativo: 'Celular Corporativo',
  fijo_benavidez: 'Fijo E.T. Benavidez',
  fijo: 'Fijo',
  celular: 'Celular',
};

export const PHONE_DISCLAIMER_TYPES = {
  celular_corporativo:
    'El corporativo de Arsat no es editable. Si es incorrecto informá a Soporte.',
  fijo_benavidez: '',
  fijo: '',
  celular: '',
};

export const USER_PHONES = {
  celular_corporativo: {
    label: PHONE_LABELS.celular_corporativo,
    disclaimer: PHONE_DISCLAIMER_TYPES.celular_corporativo,
    hasInputInterno: false,
    disabled: true,
  },
  fijo_benavidez: {
    label: PHONE_LABELS.fijo_benavidez,
    disclaimer: PHONE_DISCLAIMER_TYPES.fijo_benavidez,
    hasInputInterno: true,
    disabled: true,
  },
  fijo: {
    label: PHONE_LABELS.fijo,
    disclaimer: PHONE_DISCLAIMER_TYPES.fijo,
    hasInputInterno: false,
    disabled: false,
  },
  celular: {
    label: PHONE_LABELS.celular,
    disclaimer: PHONE_DISCLAIMER_TYPES.celular,
    hasInputInterno: false,
    disabled: false,
  },
};

export const PHONE_KEYS = {
  celular_corporativo: 'celular_corporativo',
  fijo_benavidez: 'fijo_benavidez',
  fijo: 'fijo',
  celular: 'celular',
};

export const USER_PHONES_DEFAULT_VALUES = [
  {
    tipo: PHONE_KEYS.celular_corporativo,
    preferido: false,
    numero: '',
    interno: null,
  },
  {
    tipo: PHONE_KEYS.fijo_benavidez,
    preferido: false,
    numero: '',
    interno: null,
  },
  {
    tipo: PHONE_KEYS.fijo,
    preferido: false,
    numero: '',
    interno: null,
  },
  {
    tipo: PHONE_KEYS.celular,
    preferido: false,
    numero: '',
    interno: null,
  },
];

export const IMAGE_SIZES = {
  small: 'chica',
  medium: 'mediana',
  large: 'grande',
};

export const PROFILE_IMAGE = {
  width: 140,
  height: 140,
};

export const ERRORS_USE_UPLOAD_FILE = {
  'file-too-large':
    'El archivo supera el tamaño maximo de megabytes permitidos',
};

export const propsSetValuesWorkPlace = {
  shouldValidate: true,
  shouldDirty: true,
};

export const listFilterDirectoriContact = {
  all: 'Todos',
  favoritos: 'Favoritos',
};

export const ERRORS_COMMONS = {
  default: 'Hubo un error',
  'password-incorrecto': 'Password Incorrecto',
  'clave-incorrecta': 'Clave incorrecta',
};

export const PAYCHECKS_STATUS = {
  nuevo: 'NUEVO',
  nueva_version: 'NUEVA VERSIÓN',
  visto: 'Visto',
  no_visto: 'No visto',
  none: '',
};

export const USEFUL_CONTACT_URL = {
  ART: 'https://portal.arsat.com.ar/paginas/7-art',
  OTHER: 'https://portal.arsat.com.ar/paginas/5-contactos-útiles',
};
export const TICKET_TYPE = {
  solicitud_servicio: 'SS-',
  incidente: 'IN-',
  orden_trabajo: 'OT-',
  control_cambio: 'CH-',
  no_aplica: '',
};

export const OPTIONS_TYPE_TICKET_VALUE = {
  noAplica: 'no_aplica',
  solicitud: 'solicitud_servicio',
  incidente: 'incidente',
  orden: 'orden_trabajo',
  controlCambio: 'control_cambio',
};

export const OPTIONS_TYPE_TICKET = [
  { value: OPTIONS_TYPE_TICKET_VALUE?.noAplica, label: 'No aplica' },
  {
    value: OPTIONS_TYPE_TICKET_VALUE?.solicitud,
    label: 'Solicitud de servicio',
  },
  { value: OPTIONS_TYPE_TICKET_VALUE?.incidente, label: 'Incidente' },
  { value: OPTIONS_TYPE_TICKET_VALUE?.orden, label: 'Orden de trabajo' },
  {
    value: OPTIONS_TYPE_TICKET_VALUE?.controlCambio,
    label: 'Control de cambios',
  },
];
export const TYPE_EMERGENCY_ACTIVATION = {
  emergency: 'emergencia',
};

export const ERROR_RANGE_DATEPICKER = {
  invalidRange: '* La fecha "Desde" debe ser menor a la fecha "Hasta" ',
  notValidDate: '* La fecha no es valida',
};

export const initValueFormEmergencyActivation = {
  typeTicket: 'solicitud',
  description: '',
  from_date: '',
  ticket: { tipo: OPTIONS_TYPE_TICKET_VALUE?.noAplica },
  to_date: '',
  tipo: 'emergencia',
};

export const LIMIT_NUM_TICKET = {
  MAX: 9999999,
  MIN: 999,
};

export const EMERGENCY_STATUS = {
  registrada: 'Registrada',
  aprobada: 'Aprobada',
  en_rrhh: 'Informada a RRHH',
  rechazada: 'Rechazada',
  anulada: 'Anulada',
  cancelada: 'Cancelada',
};

export const EMERGENCY_STATUS_CIRCUIT = {
  registrada: 'Registro generado',
  aprobada: 'Aprobó',
  en_rrhh: 'Informada a RRHH',
  rechazada: 'Rechazó',
  anulada: 'Anuló: RRHH',
  cancelada: 'Cancelada',
  objetada: 'Objetó',
  debe_aprobar: 'Debe aprobar',
};
export const EMERGENCY_NAME_STATUS = ['aprobada', 'rechazada', 'objetada'];

export const LIMIT_SUPPLEMENTARY_WORKS_HOURS = {
  ANUAL: 200,
  MONTHLY: 30,
};

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const TABS_TEAM_EMERGENCY = {
  currentCycle: 'actual',
  toReportCycle: 'a_informar',
  particularCycle: 'casos_particulares',
  historicCycle: 'historial',
};

export const VIEWS_GENERATION_MULTIPLE_SECTORS = {
  selectSector: 'selectSector',
  getResults: 'getResults',
  onSuccessModal: 'onSuccessModal',
};
export const TABS_TEAM_EMERGENCY_NAME = {
  actual: 'Ciclo actual',
  a_informar: 'Ciclo a informar',
  casos_particulares: 'Casos particulares',
  historial: 'Historial',
};

export const DAY_LIMIT_REPORT = 13;

export const WARNINGS_EMERGENCY_ACTIVATION = {
  MAXIMO_MENSUAL_SUPERADO: 'maximo_mensual_superado',
  MAXIMO_ANUAL_SUPERADO: 'maximo_anual_superado',
  LICENCIA_COINCIDENTE: 'licencia_coincidente',
};

export const TYPES_ANSWERS = {
  SINGLE: 'unica',
  MULTIPLE: 'multiple',
  OVERWRITE: 'sobreescribir',
};
