import dayjs from 'dayjs';
import { DATE_FORMAT } from './Constants';
import moment from 'moment';
// import moment from 'moment/min/moment-with-locales';
import 'moment/locale/es';

const language = 'es';

export const isDateValid = (date) => {
  return moment(date).isValid();
};
export const isDayjsDateValid = (date) => {
  return dayjs().isValid(date);
};
export const formatRequestDate = (date) => {
  return isDateValid(date) ? moment(date).format() : '';
};

export const formatRequestDateStartOfDay = (dateFrom) => {
  return isDayjsDateValid ? dayjs(dateFrom).startOf('day').format() : '';
};

export const formatRequestDateEndOfDay = (dateTo) => {
  return isDayjsDateValid ? dayjs(dateTo).endOf('day').format() : '';
};

export const formatDateForEdit = (date) => {
  return isDateValid(date) ? dayjs(convertDateToLocalTime(date)) : '';
};

export const formatRequestDateUTCEndOfDay = (date) => {
  return isDateValid(date) ? moment(date).endOf('day').format() : '';
};

export const formatRangeDate = (dateStart, dateEnd, lng, t) => {
  const textStart = formatReadableDate(dateStart, lng);
  let text = textStart;
  if (dateEnd) {
    const textEnd = formatReadableDate(dateEnd, lng);
    if (textEnd !== textStart) {
      text = t('rounds.dates', {
        dateStart: textStart,
        dateEnd: textEnd,
      });
    }
  } else {
    const textStart = formatReadableDate(dateStart, lng);
    text = textStart;
  }
  return text;
};

export const formatRangeTime = (dateStart, dateEnd, lng, t) => {
  const timeStart = formatDateToTime(dateStart, lng);
  let text = t('rounds.time', {
    date: timeStart,
  });
  if (dateEnd) {
    const timeEnd = formatDateToTime(dateEnd, lng);
    if (dateStart !== dateEnd) {
      text = t('rounds.times', {
        from: timeStart,
        to: timeEnd,
      });
    }
  }
  return text;
};

export const formatDate = (date, format, language = 'es') => {
  moment.locale(language);
  const localDate = convertDateToLocalTime(date);
  return isDateValid(localDate) ? moment(localDate).format(format) : '';
};

export const getMonths = () => {
  moment.locale(language);
  return moment.months();
};

export const getSecondsFromDate = (date) => {
  var a = moment.utc(date, 'HH:mm:ss'),
    b = a.clone().startOf('day');
  return a.diff(b, 'seconds');
};

export const capitalizeFirstLetter = (text) => {
  return text && text[0].toUpperCase();
};

export const formatReadableDate = (date) => {
  return formatDate(date, DATE_FORMAT.STANDARD);
};
export const formatReadableDateLight = (date) => {
  return formatDate(date, DATE_FORMAT.STANDARD_LIGHT);
};

export const formatReadableDateTime = (date) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME);
};

export const formatReadableDateTimeWithoutYear = (date) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME_WITHOUT_YEAR);
};

export const formatReadableDateTimeWithDay = (date) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME_WITH_DAY);
};

export const formatReadableTime = (date) => {
  return formatDate(date, DATE_FORMAT.READABLE_TIME);
};

export const formatHistoryDate = (date) => {
  return formatDate(date, DATE_FORMAT.HISTORY);
};
export const formatDateToReadableDay = (date) => {
  return formatDate(date, DATE_FORMAT.READABLE_DAY);
};

export const formatDateToCommunication = (date) => {
  return (
    formatDate(date, 'dddd DD') +
    ' de ' +
    formatDate(date, 'MMMM') +
    ' de ' +
    formatDate(date, 'YYYY')
  );
};

export const formatDateToReadableDayAndMonth = (date) => {
  return formatDate(
    date,
    DATE_FORMAT.READABLE_DAY_WITH_READABLE_MONTH,
    language
  );
};

export const formatDateToYear = (date) => {
  return formatDate(date, DATE_FORMAT.YEAR);
};

export const formatDateToReadableMonth = (date) => {
  return formatDate(date, DATE_FORMAT.READABLE_MONTH);
};

export const formatDateToReadableMonthWithYear = (date) => {
  return formatDate(date, DATE_FORMAT.READABLE_MONTH_WITH_YEAR);
};

export const formatDateToReadableDayFirst = (date) => {
  return formatDate(date, DATE_FORMAT.READABLE_DAY_FIRST);
};

export const formatDateToTime = (date) => {
  return formatDate(date, DATE_FORMAT.TIME);
};
export const setTimeToDate = (date, time) => {
  let baseDate = moment(date);
  let baseTime = moment(time);
  baseDate.set({
    hour: baseTime.get('hour'),
    minute: baseTime.get('minute'),
    second: baseTime.get('second'),
  });
  return baseDate.toISOString();
};

export const isDatePassed = (date) => {
  if (date === null) return false;
  return moment().diff(date) > 0;
};

export const validateDate = (value) => {
  if (value) {
    const date = moment(value);
    return (
      date.isValid() && date.isAfter('1900-01-01') && date.isBefore(new Date())
    );
  }
  return false;
};

export const removeEmptyAttributes = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
};

export const removeEmptyStringAttributes = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] === '' && delete obj[key]);
};

export const updateArray = (
  arrayToUpdate,
  idProp,
  ids,
  propToUpdate,
  propValueToUpdate
) => {
  let updatedArray = arrayToUpdate;
  updatedArray = updatedArray.map((elem) => {
    if (ids.indexOf(elem[idProp]) > -1) {
      elem[propToUpdate] = propValueToUpdate;
    }
    return elem;
  });
  return updatedArray;
};

export const convertDateToLocalTime = (dateToConvert) => {
  if (dateToConvert) {
    const date = moment(dateToConvert);
    if (date.isValid()) {
      const dateUTC = moment.utc(dateToConvert).format();
      const dateLocal = moment.utc(dateUTC).toDate();
      return dateLocal;
    }
  } else {
    return null;
  }
};

export const convertDateToLocalTimeWithFormat = (dateToConvert) => {
  if (dateToConvert) {
    const date = moment(dateToConvert);
    if (date.isValid()) {
      const dateUTC = moment.utc(dateToConvert).format('DD MMMM YYYY');
      return dateUTC;
    }
  }
};

export const getLocalHourFromDate = (date) => {
  if (date) {
    const localDate = convertDateToLocalTime(date);
    const localTime =
      localDate?.getMinutes() === 0
        ? localDate?.getHours()
        : localDate?.getHours() + ':' + localDate?.getMinutes();
    return localTime;
  }
};

export const getLocalTimeFromDate = (date) => {
  if (date) {
    const localDate = convertDateToLocalTime(date);
    const localHour = ('0' + localDate?.getHours()).slice(-2);
    const localMinutes = ('0' + localDate?.getMinutes()).slice(-2);
    return localHour + ':' + localMinutes;
  }
};

export const getLocalTimeFromDateCustom = (date) => {
  if (date) {
    const localDate = convertDateToLocalTimeWithFormat(date);
    return localDate;
  }
};

export const calculateTimeLeft = (dateFinish, dateStart = new Date()) => {
  let timeLeft = {};
  if (dateFinish === null) return timeLeft;

  dateFinish = new Date(convertDateToLocalTime(dateFinish));
  const difference = +dateFinish - +dateStart;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const formatSecondsToReadableDate = (seconds) => {
  if (!seconds) return '';
  return moment.utc(seconds * 1000).format('HH:mm:ss');
};

export const buildParamsPlots = (filters) => {
  const params = filters;
  if (filters?.date_start_filter) {
    params.date_start_filter = formatRequestDate(filters.date_start_filter);
  }
  if (filters?.date_end_filter) {
    params.date_end_filter = formatRequestDateEndOfDay(filters.date_end_filter);
  }

  return params;
};
