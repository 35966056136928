import { Tooltip, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';

export const CustomTooltip = ({ children, title, hideTooltip }) => {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: 'rgba(83, 83, 83, 1)',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(83, 83, 83, 1)',
      fontSize: 13,
      fontFamily: theme.typography.secondary,
      fontWeight: 400,
    },
  }));
  return (
    <>
      {!hideTooltip ? (
        <StyledTooltip
          title={title}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          {children}
        </StyledTooltip>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};
