import * as PhosphorIcons from '@phosphor-icons/react'; // eslint-disable-line
import CustomIconBase from './CustomIconBase';
import { CUSTOM_ICONS } from './CustomIcons';

export const PageDefaultIcon = ({ size = 24, color }) => (
  <PhosphorIcons.Note size={size} color={color} />
);

const CustomIcon = ({ name, size, color, weight, mirrored, ...props }) => {
  const SelectedPhosphorIcon = PhosphorIcons[name];

  if (SelectedPhosphorIcon) {
    return (
      <SelectedPhosphorIcon
        size={size}
        color={color}
        weight={weight}
        mirrored={mirrored}
      />
    );
  }
  if (CUSTOM_ICONS[name]) {
    return (
      <CustomIconBase
        paths={CUSTOM_ICONS[name]}
        size={size}
        color={color}
        {...props}
      />
    );
  }
  return <PageDefaultIcon size={size} color={color} />;
};

export default CustomIcon;
