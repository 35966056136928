import { Box } from '@mui/material';

export const IconWrapper = ({
  children,
  background = '#DDEBF9',
  padding = '6px',
  borderRadius = '50%',
  width = 'auto',
  height = 'auto',
  border,
  zIndex,
  position,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      background,
      padding,
      borderRadius,
      width,
      height,
      border,
      zIndex,
      position,
    }}
  >
    {children}
  </Box>
);
