import { forwardRef } from 'react';
import { IconBase } from '@phosphor-icons/react'; // eslint-disable-line

const CustomIconBase = ({ paths, name, ...props }, ref) => {
  const weights = new Map([
    ['thin', paths],
    ['light', paths],
    ['regular', paths],
    ['bold', paths],
    ['fill', paths],
    ['duotone', paths],
  ]);

  const IconComponent = forwardRef((props, ref) => (
    <IconBase ref={ref} {...props} weights={weights} />
  ));

  IconComponent.displayName = name;

  return <IconComponent {...props} ref={ref} />;
};

export default forwardRef(CustomIconBase);
